import React from 'react';
import { Content } from "../Article/components/Content";

export const ArticleContentView = ({ match, article, numero, fonts }) => {
    return(
        <section className="inner-main">
            <div className="description main-description" style={ fonts.family4 }>
                <div data-text="true" dangerouslySetInnerHTML={{ __html: article.description }} />
            </div>
            <Content match={ match } article={ article }/>

            {article.author.first_name && article.author.last_name &&
                <div className="author-block">
                    <div className="inner">
                        <div className="avatar">
                            <img src={ article.author.image } alt=""/>
                        </div>
                        <div className="informations">
                            <span className="name" style={ fonts.family1 }>
                                {article.author.first_name} {article.author.last_name}
                            </span>
                            <div style={ fonts.family4 }>
                                {article.author.metier &&
                                    <span className="metier">
                                        <i className="icon-avatar_author" />{ article.author.metier }
                                    </span>
                                }
                                {article.author.email &&
                                    <span className="email">
                                        <i className="icon-mail_author" />{article.author.email}
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }


        </section>
    )
};
