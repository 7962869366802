import * as React from "react";

const Citation = ({ fonts, datas }) => {
    return(
        <div className="component component-citation citation">
            <q style={ fonts.family3 }>{ datas.citation }</q>
            <span className="author" style={ fonts.family4 }>{ datas.author }</span>
        </div>
    )
};

export default Citation;
