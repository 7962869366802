import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from "react-router-dom";

import Loadable from 'react-loadable';

import "../style/cible.scss";
import animations from "../animations";
import { GET_CURRENT_NUMERO, UPDATE_CURRENT_NUMERO } from "../../../../../Queries";
// import 'react-html5video/dist/styles.css';

let supportsTouch, clientWidth, clientHeight;

if(typeof window !== "undefined"){
    supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

    clientWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    clientHeight = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
}

const Loading = () => <div className="loading">Loading...</div>;

const VideoContainer = Loadable({
    loader: () => import('./videoView'),
    loading: Loading
});

class ProgressiveContainerImage extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return !(nextProps.src === this.props.src || this.props.src === null);
    }

    render() {
        const { setRef, classe, src, number } = this.props;

        return(
            <ImageWithStatusText
                imageUrl={ src }
                handleImageLoaded={ this.props.handleImageLoaded }
                loaded={ this.props.loaded }
                classe={ classe }
                setRef={ setRef }
                number={ number }
            />
        );

    }
}

class ProgressiveContainer extends Component {

    constructor(props){
        super(props);

        this.state = {
            oldImage: null,
            newImage: null,
            loaded: false,
            current: 0
        };

        this.setRef = this.setRef.bind(this);

    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.src !== prevState.newImage){

            if(!prevState.oldImage){
                return {
                    oldImage: nextProps.src,
                    newImage: nextProps.src,
                    current: prevState.current
                }
            } else {
                if(prevState.newImage && nextProps.src){
                    return {
                        oldImage: prevState.newImage,
                        newImage: nextProps.src,
                        current: prevState.current + 1,
                        loaded: false
                    }
                }
            }
        }
        else return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
        // return !(nextProps.src === this.props.src || this.props.src === null);
    }

    componentDidMount() {

        if(this.ref1){
            if(this.state.current % 2 === 0){
                animations.animationViewImagesIn(this.ref1, this.ref2, 0);
            } else {
                animations.animationViewImagesIn(this.ref2, this.ref1, 0);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) { let that = this;

        if(prevState.oldImage){
            if(prevState.newImage !== this.state.newImage){

                if(this.state.current % 2 === 0){
                    animations.animationViewImagesIn(that.ref1, that.ref2, 0.6);
                } else {
                    animations.animationViewImagesIn(that.ref2, that.ref1, 0.6);
                }

            }
        }

    }

    setRef(number, ref){
        if(number === 1){
            this.props.setRef1(ref); this.ref1 = ref;
        } else if(number === 2){
            this.props.setRef2(ref); this.ref2 = ref;
        }
    }

    handleImageLoaded = () => {
        this.setState({ loaded: true });
    };

    render() {

        const { src, loading, setRefFx, setRef1, setImageRef, setRef2, couvertureViewImage1 } = this.props;


        const classes = classNames({
            'ready': this.state.loaded,
            'not-ready': !this.state.loaded,
            'inner-head': true
        });

        const classes2 = classNames({
            'loaded': loading,
            'fuck': true
        });

        let src1 = this.state.oldImage;
        let src2 = this.state.oldImage;

        if(this.state.current % 2 === 0){
            src1 = this.state.newImage;
        } else {
            src2 = this.state.newImage;
        }

        const classesImage01 = classNames({
            'image-01': true,
            'active': this.state.current % 2 === 0
        });

        const classesImage02 = classNames({
            'image-02': true,
            'active': this.state.current % 2 !== 0
        });

        return(
            <div className={ classes } data-text="true">
                {/*<div className="fx-img" ref={ (ref) => setRefFx(ref) } />*/}
                <div className="imageView" ref={ (ref) => setImageRef(ref) }>

                    <ProgressiveContainerImage
                        classe={ classesImage01 }
                        src={ src1 }
                        number={ 1 }
                        setRef={ this.setRef }
                        handleImageLoaded={ this.handleImageLoaded }
                    />

                    <ProgressiveContainerImage
                        classe={ classesImage02 }
                        src={ src2 }
                        number={ 2 }
                        setRef={ this.setRef }
                        handleImageLoaded={ this.handleImageLoaded}
                    />

                </div>
            </div>
        )
    }

}

class ImageWithStatusText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            imageStatus: "loading"
        };
    }

    handleImageErrored() {}

    render() {

        const classes2 = classNames({
            'loaded': this.props.loaded,
        });

        return (
            <img
                className={ classes2 }
                src={ this.props.imageUrl }
                onLoad={ this.props.handleImageLoaded }
                onError={ this.handleImageErrored.bind(this) }
                ref={ (ref) => {
                    this.props.setRef(this.props.number, ref);
                }}
            />
        );
    }
}

export class CibleView extends Component {

    constructor(props){
        super(props);

        this.state = {
            numero: JSON.parse(JSON.stringify(this.props.numero)),
            article: JSON.parse(JSON.stringify(this.props.article)),
            fonts: JSON.parse(JSON.stringify(this.props.fonts)),
            isMounted: false
        };

        this.couvertureViewImage1 = null;
        this.couvertureViewImage2 = null;
        this.couvertureViewImage = null;
        this.couvertureTitleView = null;
        this.couvertureViewBtn = null;

        this.setImageRef1 = this.setImageRef1.bind(this);
        this.setImageRef2 = this.setImageRef2.bind(this);
        this.setImageRef = this.setImageRef.bind(this);
        this.setRefFx    = this.setRefFx.bind(this);

    }

    static getDerivedStateFromProps(props, state) {
        if (props.article._id !== state.article._id || props.article.isRead !== state.article.isRead) {
            return {
                article: props.article,
                numero: props.numero,
                fonts: props.fonts
            };
        }

        return null;
    }

    animateTitle() {
        animations.CouvertureViewTitle(this.couvertureTitleView);
    }

    animate(isMounting = false) {

        let yAnim = - (clientHeight / 3);
        yAnim = -200;

        if(typeof window !== "undefined" && clientWidth < 426) {
            yAnim = -70;
        }

        if(typeof window !== "undefined" && clientWidth < 321) {
            yAnim = -40;
        }

        if (this.props.view === "couverture") {
            if(isMounting){
                animations.CouvertureViewCouverture(this.couvertureView, 0);
                animations.CouvertureViewImageCouverture(this.couvertureViewImage, 0);
            } else {
                animations.CouvertureViewCouverture(this.couvertureView);
                animations.CouvertureViewImageCouverture(this.couvertureViewImage);
            }
        } else if (this.props.view === "article") {
            if(isMounting){
                animations.CouvertureViewArticle(this.couvertureView, yAnim, 0);
                animations.CouvertureViewImageArticle(this.couvertureViewImage, yAnim, 0);
            } else {
                animations.CouvertureViewArticle(this.couvertureView, yAnim);
                animations.CouvertureViewImageArticle(this.couvertureViewImage, yAnim);
            }
        }

    }

    componentDidMount() {
        this.animate(true);
        animations.CouvertureViewTitle(this.couvertureTitleView, 0);
        animations.setCouvertureViewBtn(this.couvertureViewBtn, this.props.view);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.view !== this.props.view) {
            this.animate();
            this.animateTitle();
            animations.CouvertureViewBtn(this.couvertureViewBtn, this.props.view);
        }
    }

    setImageRef1(ref) {
        this.couvertureViewImage1 = ref;
    };

    setImageRef(ref) {
        this.couvertureViewImage = ref;
    };

    setImageRef2(ref) {
        this.couvertureViewImage2 = ref;
    };

    setRefFx(ref) {
        this.couvertureViewImageFX = ref;
    };

    getNumeroInNumeros(numeroObj, numeros) {
        return numeros.filter((numero) => numero._id === numeroObj._id)
    }

    openLink = (link, e) => {
        e.preventDefault();

        this.props.startRead();

        this.props.history.push(link);
    };

    getToLastRead = (article, numero, e) => {

        e.preventDefault();

        this.props.continueRead();

        const lastNumero = this.props.client.readQuery({ query: GET_CURRENT_NUMERO });

        if(lastNumero.currentNumero._id === article.numero._id) {
            this.props.history.push(`/${article.numero.slug}/${article.category.slug}/${article.slug}`);
            return null;
        }

        let currentNumeroObj = this.getNumeroInNumeros(article.numero, this.props.numeros)[0];

        this.props.client.writeQuery({
            query: UPDATE_CURRENT_NUMERO,
            data: {
                currentNumero: currentNumeroObj
            }
        });

        this.props.history.push(`/${article.numero.slug}/${article.category.slug}/${article.slug}`);

    };

    openCouv = (link, e) => {
        e.preventDefault();

        this.props.captureCouvRead();
        this.props.history.push(link);

    };

    lastReadIsInArticles(lastArticle, numeros) {

        if(!lastArticle) return false;

        let IsIn = numeros.map((numero) => {
            return numero.articles.filter((article) => {
                return article._id === lastArticle._id;
            })
        })[0];

        if(IsIn.length < 1){
            return false;
        } else {
            return lastArticle;
        }

    }

    render() {

        let lastArticleRead = this.props.user.lastArticleRead;
        lastArticleRead = this.lastReadIsInArticles(lastArticleRead, this.props.numeros);

        let wallpaperImg;

        if(this.props.view === "couverture"){
            wallpaperImg = this.state.numero.custom.image_de_fond;
        } else {
            wallpaperImg = this.state.article.wallpaper.url;
        }

        const classesVideo = classNames({
            'video-outer-p': true,
            'active': this.props.video,
        });

        return(
            <div className={ this.props.classes } ref={(ref) => this.couvertureView = ref}>
                <div className="article-couverture">

                    {this.props.view === "couverture" &&

                        <div className={ classesVideo }>
                            {typeof window !== "undefined" && <VideoContainer
                                className="video-container"
                                controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                                //poster={ wallpaperImg }
                                videoValue={ this.props.video }
                                onCanPlayThrough={() => {
                                    // Do stuff
                                }}>
                                <source src={ this.state.numero.custom.video.fichier } type="video/mp4"/>
                            </VideoContainer>}
                        </div>

                    }

                    {this.props.view === "couverture" &&

                        <div className="inner-outer">

                            <div className="inner-center" data-fade="true">
                                <div className="video-outer" style={ this.state.fonts.family1 }>

                                    <button id="btn-play-video" onClick={ this.props.videoIn }>
                                        <svg width="89px" height="90px" viewBox="0 0 89 90" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                                            <g id="DESKTOP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g id="HOMEPAGE"
                                                   transform="translate(-431.000000, -345.000000)"
                                                   stroke="#FFFFFF" strokeWidth="4">
                                                    <g id="Group-5" transform="translate(433.000000, 258.000000)">
                                                        <g id="Group-4" transform="translate(0.000000, 50.500000)">
                                                            <g id="Group-2" transform="translate(0.000000, 39.000000)">
                                                                <circle id="Oval" cx="42.5" cy="42.5" r="42.5" />
                                                                <polygon
                                                                    id="Triangle"
                                                                    strokeLinejoin="round"
                                                                    transform="translate(45.648148, 42.500000)
                                                                    rotate(-270.000000) translate(-45.648148, -42.500000) "
                                                                    points="45.6481481 28.3333333 62.962963 56.6666667 28.3333333 56.6666667" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>

                                    <div className="text" dangerouslySetInnerHTML={{ __html: this.state.numero.custom.video.titre }} />
                                    <span className="subtitle-video"
                                          style={ this.state.fonts.family3 }>{this.state.numero.custom.video.sous_titre}</span>
                                </div>

                                <div className="call-to-action">
                                    { lastArticleRead.slug &&
                                    <Link to={`/${lastArticleRead.numero.slug}/${lastArticleRead.category.slug}/${lastArticleRead.slug}`}
                                          onClick={(e) => this.getToLastRead(lastArticleRead, this.props.numeros, e) }
                                          className="start-btn" style={ this.state.fonts.family1 } data-text="true">
                                        <div ref={(ref) => this.couvertureViewBtn = ref}>
                                    <span className="inner-start">
                                        Reprendre la lecture
                                        <i className="icon-arrow_right" />
                                    </span>
                                        </div>
                                    </Link>
                                    }

                                    { !lastArticleRead.slug &&
                                    <Link to={`/${this.state.numero.slug}/${this.state.article.category.slug}/${this.state.article.slug}`}
                                          onClick={ (e) =>
                                              this.openLink(`/${this.state.numero.slug}/${this.state.article.category.slug}/${this.state.article.slug}`,e)
                                          } className="start-btn" style={ this.state.fonts.family1 } data-text="true">
                                        <div ref={(ref) => this.couvertureViewBtn = ref}>
                                    <span className="inner-start">
                                        Commencer la lecture
                                        <i className="icon-arrow_right" />
                                    </span>
                                        </div>
                                    </Link>
                                    }
                                </div>

                            </div>
                        </div>

                    }

                    <div className="inner">
                        <Link to={`/${this.state.numero.slug}/${this.state.article.category.slug}/${this.state.article.slug}`}
                              onClick={(e) =>
                                  this.openCouv(`/${this.state.numero.slug}/${this.state.article.category.slug}/${this.state.article.slug}`, e) }>
                            <div ref={ (ref) => this.couvertureTitleView = ref }>

                                <span className="chapitre" style={ this.state.fonts.family2 } data-text="true">
                                    <span className="number">{ this.state.article.category.number }</span>
                                    <span className="titles">
                                        <span className="title">
                                            { this.state.article.category.title }
                                        </span>
                                        <span className="subtitle" style={ this.state.fonts.family3 }>
                                            { this.state.article.category.custom.subtitle }
                                        </span>
                                    </span>
                                </span>

                                <h1
                                    style={ this.state.fonts.family1 } data-text="true">
                                    {this.state.article.title}
                                </h1>

                                {this.props.view === "couverture" &&
                                <div className="outer-i">
                                    <div className="time" data-text="true" style={ this.state.fonts.family4 }>
                                        <i className="icon-time" />
                                        <span className="inner">
                                            { this.state.article.timeForRead }mn
                                        </span>
                                    </div>

                                    <IsRead article={ this.state.article }
                                            fonts={ this.state.fonts }
                                            user={ this.props.user }
                                            unsetArticleReaded={ this.props.unsetArticleReaded }
                                            setArticleReaded={ this.props.setArticleReaded }
                                            isCouverture={ false }
                                    />

                                </div>}

                            </div>
                        </Link>
                    </div>

                    <ProgressiveContainer src={ wallpaperImg }
                        setRef1={ this.setImageRef1 }
                        setRef2={ this.setImageRef2 }
                        setRefFx={ this.setRefFx }
                        setImageRef={ this.setImageRef }
                        couvertureViewImage={ this.couvertureViewImage }
                        couvertureViewImage1={ this.couvertureViewImage1 }
                        couvertureViewImage2={ this.couvertureViewImage2 }
                    />

                    {this.props.view === "article" &&
                    <div className="outer-i" data-text="true">
                        <div className="time" style={ this.state.fonts.family2 }>
                            <i className="icon-time" />
                            <span className="inner">
                                            { this.state.article.timeForRead }mn
                                        </span>
                        </div>
                        <IsRead
                            unsetArticleReaded={ this.props.unsetArticleReaded }
                            setArticleReaded={ this.props.setArticleReaded }
                            article={ this.state.article }
                            fonts={ this.state.fonts }
                            user={ this.props.user }
                            isCouverture={ true } />
                    </div>}

                </div>
            </div>
        )
    }



}

class IsRead extends Component {

    articleIsRead(user, article) {
        if(user.articles) {
            return user.articles.some((articleObj) => {
                return (articleObj._id === article._id && articleObj.readed);
            });
        }
    }

    setRead = (article) => {
        if(this.props.isCouverture)
            return this.props.setArticleReaded(article)
    };

    unsetRead = (article) => {
        if(this.props.isCouverture)
            return this.props.unsetArticleReaded(article)
    };

    render() {

        const { article, fonts, user, isCouverture } = this.props;

        if(this.articleIsRead(user, article)){
            return(
                <div className="status" data-text={ !isCouverture }
                     style={ fonts.family2 } onClick={() => this.unsetRead(article) }>
                    <i className="icon-status_done" />
                    <span className="inner">Lu</span>
                </div>
            )
        } else {
            return(
                <div className="status" data-text={ !isCouverture }
                     style={ fonts.family2 } onClick={() => this.setRead(article) }>
                    <i className="icon-status_undone" />
                    <span className="inner">Non lu</span>
                </div>
            )
        }
    }

}
