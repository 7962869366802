import React, { Component } from 'react';
import classNames from "classnames";
import { SettingsContext } from "../../../../Magazine/MagazineSettings";

import "../style/article.scss";
import MetasArticle from "../../Metas/Metas";

class ArticleView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingVar: true,
        }

    }

    render() {

        const { magazine, article, articleDetails, numero, scrolled, heightFrame, isPreview, previewLoading }
        = this.props;

        const classes = classNames({
            'scrolled': scrolled,
            'article': true,
        });

        return(
            <SettingsContext.Consumer>
                {({ fonts, logo }) => {

                    if(isPreview()){
                        if(previewLoading)
                            return null;
                    }

                    return(
                        <article className={ classes }>
                            <MetasArticle article={ article }
                                          articleDetails={ articleDetails }
                                          magazine={ magazine }/>
                        </article>
                    )
                }}
            </SettingsContext.Consumer>
        )
    }

}

export default ArticleView;
