import * as React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import Numero from "./Numero/Numero";
import { withApollo } from "react-apollo";
import { Route, Switch } from "react-router";
import { Root } from "./Root";

import "./theme.scss";
import "../../icofonts/icofonts.scss";

import { PWA } from "./PWA/PWA";
import RGPD from "./RGPD/RGPD";
import Page from "./Page/Page";
import { GET_CURRENT_NUMERO, GET_MENU, UPDATE_CURRENT_NUMERO } from "../../../Queries";
import { connect } from "react-redux";

class Index extends Component {


    constructor(props) {
        super(props);

        this.state = {
            menuShow: false,
        }
    }

    toggleMenu = (toggle = null, numero = null) => {

        if(numero) {

            const lastNumero = this.props.client.readQuery({ query: GET_CURRENT_NUMERO });

            if(lastNumero.currentNumero._id === numero._id) {
                return null;
            }

            this.props.client.writeQuery({
                query: UPDATE_CURRENT_NUMERO,
                data: {
                    currentNumero : numero
                }
            });

            this.props.history.push("/" + numero.slug);
        }

        if(toggle === false) {
            this.setState(() => {
                return {
                    menuShow: false
                }
            });

            this.props.client.writeQuery({
                query: GET_MENU,
                data: {
                    getMenu: false,
                }
            });

        } else {
            this.setState((prevState) => {

                this.props.client.writeQuery({
                    query: GET_MENU,
                    data: {
                        getMenu: !prevState.menuShow,
                    }
                });

                return {
                    menuShow: !prevState.menuShow
                }
            });

        }
    };

    render() {
        return(
            <div className="com-ent">
                <Switch>
                    <Route exact path="/credits" render={ props => (
                        <Page {...props} toggleMenu={ this.toggleMenu } menuShow={ this.state.menuShow } />
                    )}/>
                    <Route exact path="/nous-contacter" render={ props => (
                        <Page {...props} toggleMenu={ this.toggleMenu } menuShow={ this.state.menuShow } />
                    )}/>
                    <Route exact path="/" render={ props => (
                        <Root {...props} toggleMenu={ this.toggleMenu } menuShow={ this.state.menuShow } />
                    )}/>
                    <Route path="/index.html" render={ props => (
                        <Root {...props} toggleMenu={ this.toggleMenu } menuShow={ this.state.menuShow } />
                    )}/>
                    <Route path="/:id" render={ props => (
                        <Numero {...props} toggleMenu={ this.toggleMenu } menuShow={ this.state.menuShow } />
                    )}/>
                </Switch>
                <PWA />
                <RGPD />
            </div>
        )
    }

}

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo( Index )));
