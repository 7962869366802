import { Component } from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as React from "react";

import { SettingsContext } from "../../../../../Magazine/MagazineSettings";

class VoteView extends Component {

    voteClick = (link, e) => {
        e.preventDefault();
        window.open(link,'_blank');
        this.props.voteAction();
    };

    shouldComponentUpdate(prevProps) {
        // return (this.props.video && prevProps.video !== this.props.video)
        return (prevProps.video !== this.props.video)
    }

    render() {

        return(
            <SettingsContext.Consumer>
                {({ fonts, logo, custom }) => {

                    if(this.props.video){
                        return(
                            <div className="vote-link return" onClick={ this.props.videoOut } data-text="true">
                                <i className="icon-arrow_left" />
                                <span>Retour à l'accueil</span>
                            </div>
                        )
                    }

                    if(!custom.voteLink){
                        return null;
                    }

                    return (
                        <a className="vote-link" href={ custom.voteLink } onClick={(e) => this.voteClick(custom.voteLink, e)} data-text="true">
                            <span>Vote</span>
                            <i className="icon-arrow_right" />
                        </a>
                    )
                }}
            </SettingsContext.Consumer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        video: state.video
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        voteAction: (value) => {
            dispatch({
                type: "VOTE_BOUTON",
            })
        },
        videoOut: () => {
            dispatch({
                type: "VIDEO_OUT"
            })
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VoteView));
