import GSAPInit from "../../../Animations/GSAP";

class GsapComponent {

    CouvertureViewArticle= (gsap, target, yAnim, speed = 0.4) => {
        if(target === null || !gsap) return;

        gsap.TweenMax
            .to(target, speed, { y: yAnim, rotation: 0.0001, ease: gsap.Power3.easeInOut })

    };
    CouvertureViewCouverture= (gsap, target, speed = 0.4) => {
        if(target === null || !gsap) return;

        gsap.TweenMax
            .to(target, speed, { y: 0, rotation: 0.0001, ease: gsap.Power3.easeInOut })

    };
    CouvertureViewTitle= (gsap, target, speed = 0.4) => {
        if(target === null || !gsap) return;

        const timeline = new gsap.TimelineMax();

        timeline
            .fromTo(target, speed, { y: 0, autoAlpha: 0 }, { y: 0, autoAlpha: 1 }, (speed !== 0) ? "+=0.2" : "+=0")

    };
    setCouvertureViewBtn= (gsap, target, direction) => {
        if(target === null || !gsap) return;

        if(direction === "couverture"){
            gsap.TweenMax.set(target, { autoAlpha: 1 }, "+=0.3")
        } else if(direction === "article"){
            gsap.TweenMax.set(target, { autoAlpha: 0 }, "+=0.3")
        }
    };
    CouvertureViewBtn= (gsap, target, direction) => {
        if(target === null || !gsap) return;

        if(direction === "couverture"){
            gsap.TweenMax.to(target, 0.3, { autoAlpha: 1 }, "+=0.3")
        } else if(direction === "article"){
            gsap.TweenMax.to(target, 0.3, { autoAlpha: 0 }, "+=0.3")
        }

    };
    CouvertureViewImageArticle= (gsap, target, yAnim, speed = 0.4) => {
        if(target === null || !gsap) return;

        gsap.TweenMax.to(target, speed, { y: 0, rotation: 0.0001, ease: gsap.Power3.easeInOut });
    };
    CouvertureViewImageCouverture= (gsap, target, speed = 0.4) => {
        if(target === null || !gsap) return;

        const timeline = new gsap.TimelineMax();

        gsap.TweenMax.to(target, speed, { y: 0, rotation: 0.0001, ease: gsap.Power3.easeInOut });

    };
    animationViewImagesIn= (gsap, imageTarget1, imageTarget2, speed) => {
        if(!gsap) return;
        if(imageTarget1 === null || imageTarget2 === null) return;

        const timeline = new gsap.TimelineMax();

        timeline
            .fromTo(imageTarget1, speed, { autoAlpha: 0 }, { autoAlpha: 1 }, "+=0.4")
            .fromTo(imageTarget2, speed, { autoAlpha: 1 }, { autoAlpha: 0 }, "-=" + speed)

    };

}

let gsap = new GsapComponent();
let gsapInit = new GSAPInit();

export default {
    CouvertureViewArticle: (target, yAnim, speed) => {
        gsapInit.importGSAP().then((module) => {
            gsap.CouvertureViewArticle(module, target, yAnim, speed)
        });
    },
    CouvertureViewCouverture: (target, speed) => {
        gsapInit.importGSAP().then((module) => {
            gsap.CouvertureViewCouverture(module, target, speed)
        });
    },
    CouvertureViewTitle: (target, speed) => {
        gsapInit.importGSAP().then((module) => {
            gsap.CouvertureViewTitle(module, target, speed)
        });
    },
    setCouvertureViewBtn: (target, direction) => {
        gsapInit.importGSAP().then((module) => {
            gsap.setCouvertureViewBtn(module, target, direction)
        });
    },
    CouvertureViewBtn: (target, direction) => {
        gsapInit.importGSAP().then((module) => {
            gsap.CouvertureViewBtn(module, target, direction)
        });
    },
    CouvertureViewImageArticle: (target, yAnim, speed) => {
        gsapInit.importGSAP().then((module) => {
            gsap.CouvertureViewImageArticle(module, target, yAnim, speed)
        });
    },
    CouvertureViewImageCouverture: (target, speed) => {
        gsapInit.importGSAP().then((module) => {
            gsap.CouvertureViewImageCouverture(module, target, speed)
        });
    },
    animationViewImagesIn: (imageTarget1, imageTarget2, speed) => {
        gsapInit.importGSAP().then((module) => {
            gsap.animationViewImagesIn(module, imageTarget1, imageTarget2, speed)
        });
    },
};
