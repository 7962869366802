import React from 'react';

import { Link } from "react-router-dom";
import classNames from "classnames";
import { SettingsContext } from "../../../../Magazine/MagazineSettings";

import "../style/couverture.scss";

const ProgressiveContainer = ({ src, loading }) => {

    const classes = classNames({
        'ready': loading,
        'inner-head': true
    });

    return(
        <div className={ classes }>
            <div className="fx-img" />
            {src && <img src={ src } alt='an image' />}
        </div>
    )
};

const ArticleCouverture = ({ article, numero, firstRead, fonts, captureStartRead, captureCouvRead }) => {

    if(article.slug){
        return(
            <div className="article-couverture">

                <div className="inner">

                    <Link to={`/${numero.slug}/${article.category.slug}/${article.slug}`} onClick={ captureCouvRead }>

                        <span className="chapitre" style={ fonts.family2 } data-text="true">
                            <span className="number">{ article.category.number }</span>
                            { article.category.title }
                        </span>

                        <h2 style={ fonts.family1 } data-text="true">{article.title}</h2>
                        <p>{article.description}</p>

                    </Link>

                </div>

                {/*<ProgressiveImage src={ article.wallpaper.url } placeholder={ article.wallpaper.preview }>*/}
                    {/*{(src, loading) => ( <ProgressiveContainer src={ src } loading={ !loading } />)}*/}
                {/*</ProgressiveImage>*/}
                {/*<ProgressiveImage src={ article.wallpaper.url } placeholder="">
                    {(src, loading) => ( <ProgressiveContainer src={ src } loading={ !loading } />)}
                </ProgressiveImage>*/}

                <Link to={`/${numero.slug}/${firstRead.category.slug}/${firstRead.slug}`}
                      className="start-btn" style={ fonts.family1 } data-text="true" onClick={ captureStartRead }>
                        Commencer la lecture
                </Link>

            </div>
        )
    }

    return null
};

const CouvertureView = ({ user, magazine, numero, match, couverture, firstRead, heightCouverture, captureStartRead, captureCouvRead, captureEntreeRead }) => {

    const selectedCouverture = couverture.firstArticle;

    if(selectedCouverture)
        return (
            <SettingsContext.Consumer>
                {({ fonts, logo }) => {
                    return null;
                }}
            </SettingsContext.Consumer>

        );

    return null;
};

export default CouvertureView;

const getCouverture = ( categories, numero ) => {

    const firstArticle = numero.articles.filter((article) => {
        return article.category._id === categories[0]._id
    })[0];

    if (firstArticle) return firstArticle;

};

const ChapitreView = ({ user, categories, numero, articles, fonts, captureEntreeRead }) => {

    let chapitre = (article) => {
        return categories.find(( category ) => category._id === article.category._id);
    };

    return (
        <div>
            {articles.map((article, index) => {

                if (article) {

                    let isRead = () => {
                        if(user.articles) {
                            return user.articles.some((articleObj) => {
                                return (articleObj._id === article._id && articleObj.readed);
                            });
                        } return false;
                    };

                    return (
                        <div className="chapitre" key={ index }>
                            <Link to={`/${numero.slug}/${chapitre(article).slug}/${article.slug}`}
                                  onClick={ () => captureEntreeRead(index + 1) }>
                                <span className="chapitre-titre" style={ fonts.family2 } data-text="true">
                                    <span className="number">{ chapitre(article).number }</span>
                                    { chapitre(article).title}
                                </span>
                                <span className="title" data-text="true" style={ fonts.family1 }>{ article.title }</span>
                                <div className="outer-i">
                                    <div className="time" data-text="true" style={ fonts.family4 }>
                                        <i className="icon-time" />
                                        <span className="inner">
                                            { article.timeForRead }mn
                                        </span>
                                    </div>
                                    {isRead() &&
                                        <div className="status" data-text="true" style={ fonts.family4 }>
                                            <i className="icon-status_done" />
                                            <span className="inner">
                                                Lu
                                            </span>
                                        </div>
                                    }
                                    {!isRead() &&
                                    <div className="status" data-text="true" style={ fonts.family4 }>
                                        <i className="icon-status_undone" />
                                        <span className="inner">
                                                Non lu
                                            </span>
                                    </div>
                                    }
                                </div>
                            </Link>
                        </div>
                    );
                } return null
            })}
        </div>
    );

};
