import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuView from "./views/MenuView";
import { GET_ALL, GET_CURRENT_NUMERO } from "../../../../Queries";
import { Query } from "react-apollo";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (
            <Query query={ GET_ALL } variables={
                {
                    magazine_slug: this.context.magazine.slug,
                    offset: 0
                }
            } fetchPolicy="cache-first">
                {({ loading, error, data, fetchMore }) => {
                    let datas = data.datas;

                    return (
                        <Query query={ GET_CURRENT_NUMERO }>
                            {({loading, error, data, client}) => {

                                return(
                                    <MenuView show={ this.props.show }
                                              numero={ data.currentNumero }
                                              match={ this.props.match }
                                              history={ this.props.history }
                                              toggleMenu={ this.props.toggleMenu }
                                              data={ datas }
                                              captureContactClick={ this.props.captureContactClick }
                                              openRGPDPopup={ this.props.openRGPDPopup }
                                    />
                                )
                            }}
                        </Query>
                    )

                }}
            </Query>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        openRGPDPopup: () => {
            dispatch({
                type: "OPEN_WINDOW_RGPD",
                value: true
            })
        },
        captureContactClick: () => {
            dispatch({
                type: "CLICK_CONTACT"
            })
        },
    };
};

Menu.contextTypes = {
    magazine: PropTypes.object
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));

