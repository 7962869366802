import React, { Component, PureComponent } from 'react';
import { Route, Switch } from "react-router-dom";

import Article from "../../../Article/Article";
import Couverture from "../../../Couverture/Couverture";
import Cible from "../../../Cible/Cible";

import { Redirect, withRouter } from "react-router";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import Chapitre from "../../../Chapitre/Chapitre";

class MainView extends PureComponent {

    render() {
        let { match, isPreview, previewLoading } = this.props;
        return (
            <main>
                {/*isPreview() && previewLoading &&
                <div className="loading-outer">
                    <div className="loading">

                        <ProgressiveImage src={ loading } placeholder="">
                            {(src, loading) => {
                                if(!loading) return <img src={ src } />;
                                return null;
                            }}
                        </ProgressiveImage>

                    </div>
                </div>
                */}

                <Route path={[ `${match.url}/:chapitre/:slug`, `${match.url}` ]} render={ (props) => {
                    return <Cible {...props} toggleMenu={ this.props.toggleMenu } />
                }} />

                {/*<Cible />*/}
                <div className="head">
                    <Route path={`${match.url}/:chapitre/:slug`} component={ Article } />

                </div>
                <Switch>
                    <Route exact path={`${match.url}/:chapitre`} render={() => {
                        return(
                            <Redirect to={`${match.url}`} />
                        )
                    }}/>
                    <Route path={`${match.url}/:chapitre/:slug`} render={ (props) => {
                        return <Chapitre {...props} />
                    }} />
                    <Route path={`${match.url}`} component={ Couverture } />

                </Switch>
            </main>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(MainView)));
