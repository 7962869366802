import React, { Component } from 'react';

import AnimBackground2 from "./AnimBackground2";
import animations from "../animations";
import { Link } from "react-router-dom";

import { SettingsContext } from "../../../../Magazine/MagazineSettings";
import { NumeroMenu } from "../../Numero/components/views/NumeroMenu";

import "../style/menu.scss";

function waitAnimationEnd(fn, duration)  {
    setTimeout(() => {
        fn();
    }, duration);
}

class ChapitreMenu extends Component {

    constructor(props){
        super(props);

        this.ref = "";

        if(this.props.articles.length > 0)
            this.state = {
                backgroundImage: this.props.articles[0].wallpaper.formats.menu,
                backgroundImageHover: this.props.articles[0].wallpaper.formats.menu
            };
        else
            this.state = {
                backgroundImage: "",
                backgroundImageHover: ""
            };
    }

    changeBackgroundHover(article) {
        this.setState({
            backgroundImageHover: article.wallpaper.formats.menu
        });
    };

    changeBackgroundOut = () => {
        this.setState({
            backgroundImageHover: this.state.backgroundImage
        });
    };

    getFirstArticle() {
        return this.props.articles[0];
    }

    render() { let that = this; const { articles, category, fonts, numero, toggleMenu } = this.props;

        if(this.props.articles) {

            return (
                <li key={category._id} className="chapitre">
                    <Link to={`/${numero.slug}/${category.slug}/${this.getFirstArticle().slug}`}
                          onClick={ toggleMenu }>
                    <div className="inner">
                        <div className="categories" style={fonts.family1}>
                        <span className="number">
                            <span className="inner">
                                {category.number}
                            </span>
                        </span>
                            <div className="chapitre-wallpaper"
                                 style={{backgroundImage: `url(${ this.state.backgroundImageHover })`}}/>
                            <div className="chapitre-titles">
                                <span className="chapitre-title">{category.title}</span>
                                <span className="chapitre-subtitle" style={fonts.family2}>{category.custom.subtitle} </span>
                            </div>
                        </div>
                        {/*<ul className="articles">
                        { articles.map(article => (
                            <li key={ article._id } style={ fonts.family3 } onMouseOver={ () => this.changeBackgroundHover(article) }
                                onMouseLeave={ this.changeBackgroundOut }>
                                <Link to={`/${numero.slug}/${category.slug}/${article.slug}`}
                                      onClick={ toggleMenu } key={ article._id }
                                >{article.title}
                                </Link>

                            </li>
                        ))}
                    </ul>*/}
                    </div>
                </Link>
                </li>
            )

        }

    }


}

class MenuView extends Component {

    constructor(props){
        super(props);

        this.ref = "";
        this.footerRef = "";

        this.state = {
            render: false,
            animBackground: false,
            numeroMenu: null
        };

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.show !== prevState.show)
            return {
                show: nextProps.show
            };

        return null;
    }

    animationMenu(show) {

        if(show){

            this.setState({ render: !this.state.render });

            setTimeout(() => {
                animations.onEnter(this.ref);
                animations.footerMenuEnter(this.footerRef);
            }, 10);

        } else {

            this.setState({
                animBackground: !this.state.animBackground,
            });

            animations.onLeave(this.ref);
            animations.footerMenuLeave(this.footerRef);

            setTimeout(() => {
                this.setState({
                    render: !this.state.render,
                });
            }, 200);

        }
    }

    setReference = (ref) => {
        this.ref = ref;
    };

    setFooterReference = (ref) => {
        this.footerRef = ref;
    };

    componentDidUpdate(prevProps, prevState) {

        if(prevState.animBackgroundAnimation !== true)
            if(prevProps.show !== this.state.show){
                this.animationMenu(this.state.show);
            }

    }

    componentDidMount(){ let numeroMenu;

        this.setState({
            show: this.props.show,
        });
    }

    getCategories(categories) {

        const categoriesArr = categories.slice(0);
        categoriesArr.sort((a, b) => {
            return (a.order - b.order)
        });

        return categoriesArr.slice(0);
    }

    getArticles(articles, category) {
        return articles.filter((article) => article.category._id === category._id);

    }

    openLink = (link, e) => {
        e.preventDefault();
        this.props.toggleMenu(false);

        this.props.history.push(link);
    };

    checkWindowWidth() {
        return window.innerWidth <= 425
    };

    render(){ let that = this;

        return (
            this.state.render ?
                <SettingsContext.Consumer>
                    {({ fonts, contact }) => (
                        <div className="menu" style={ this.state.style }>
                            <AnimBackground2 animation={ this.state.animBackground } />

                            <div className="menu-inner" ref={ this.setReference }>
                                { this.state.numeroMenu }
                                <div className="title-outer">
                                    <div className="title" style={ fonts.family1 }>Sommaire</div>
                                    <div className="subtitle" style={ fonts.family2 }>Camille & le monde merveilleux de COM-ENT</div>
                                </div>
                                <div className="articles-test">
                                    <ul>
                                        {that.getCategories(this.props.numero.categories).map((category, index) => {

                                            const articles = that.getArticles(this.props.numero.articles, category);

                                            return(<ChapitreMenu
                                                category={ category }
                                                articles={ articles }
                                                fonts={ fonts }
                                                toggleMenu={ this.props.toggleMenu }
                                                numero={ this.props.numero }
                                                key={ index }
                                            />);

                                        })}
                                    </ul>
                                </div>

                            </div>
                            <div className="footer-menu" ref={ this.setFooterReference }>
                                <div className="inner-footer-menu">
                                    <ul style={ fonts.family1 }>
                                        <li>
                                            <Link to={"/credits"}
                                                  onClick={(e) => this.openLink("/credits", e) }>
                                                Crédits
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/nous-contacter"}
                                                  onClick={(e) => this.openLink("/nous-contacter", e) }>
                                                Nous contacter
                                            </Link>
                                        </li>
                                        {contact &&
                                            <li>
                                                <a href={"mailto:" + contact}
                                                   onClick={ this.props.captureContactClick }>
                                                    Contact
                                                </a>
                                            </li>
                                        }
                                        <li>
                                            <div onClick={ this.props.openRGPDPopup }>
                                                Gestion des cookies
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="credits" style={ fonts.family3 }>Tous droits réservés - <a href="https://www.ridmi.io" target="_blank">RIDMI</a> 2018</div>
                                </div>
                            </div>
                        </div>
                    )}
                </SettingsContext.Consumer>
            : null
        )
    }
}

export default MenuView;
