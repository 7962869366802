import React, { Component } from 'react';
import { GET_ARTICLE, GET_CURRENT_NUMERO } from "../../../../Queries";
import { Query } from "react-apollo";
import classNames from "classnames";
import { SettingsContext } from "../../../Magazine/MagazineSettings";
import PropTypes from "prop-types";
import ArticleContent from "./ArticleContent";

import Player from "./Player";

import animations from "./animations";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router";

let supportsTouch, clientWidth, clientHeight;

if(typeof window !== "undefined"){
    supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

    clientWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    clientHeight = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
}

class Chapitre extends Component {

    constructor(props) {
        super(props);

        this.state = {
            display: false,
            ref: null
        };

        this.handleScroll = this.handleScroll.bind(this);

    }

    componentDidMount() {

        // let yAnim = -200;
        //
        // if(typeof window !== "undefined" && clientWidth < 425) {
        //     yAnim = -70;
        // }

        // if(this.ref) {
        //     animations.ArticleEnter(this.ref, 0);
        // }

        if(this.ref){
            animations.ArticleEnter(this.ref);
        }

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {

        if(this.ref) {
            animations.ArticleLeave(this.ref);
        }

        window.removeEventListener('scroll', this.handleScroll);
    }

    // componentDidUpdate() {
    //     animations.ArticleEnter(this.ref);
    // }


    findArticlesToChapitre(articles, numero) {
        if(!articles) {
            this.props.history.push("/"); return null;
        }
        return articles.filter((article) => article.category.slug === this.props.match.params.chapitre);
    }

    getArticleWithQuery(articles, numero) {

        // if(!this.props.match.params.slug){
        //     return null;
        // }

        if(!articles) {
            this.props.history.push("/"); return null;
        }

        let article = articles.filter((article) => article.slug === this.props.match.params.slug)[0];

        if(!article) {
            return null;
        }

        return article;

    }

    handleScroll() {
        if( window.scrollY >= 539){
            if(!this.state.display)
                this.setState({ display: true })
        } else {
            if(this.state.display)
                this.setState({ display: false })
        }
    }

    setReference = (ref) => {
        this.ref = ref;
    };

    render() {

        if(!this.props.match.params.slug) return null;

        return (
            <Query query={ GET_CURRENT_NUMERO }>
                {({loading, error, data, client}) => {

                    if(error) return null;
                    if(loading) return null;

                    let currentNumero = data.currentNumero;
                    let articles = currentNumero.articles;
                    let articles_chapitre = this.findArticlesToChapitre(articles, currentNumero);
                    let article = this.getArticleWithQuery(articles, currentNumero);

                    if(!article) return null;

                    return(
                        <Query query={ GET_ARTICLE }
                               variables={{ magazine_slug: this.context.magazine.slug, article_id: article._id }}
                               fetchPolicy="cache-first">
                            {({ loading, error, data, networkStatus }) => {

                                if(article)
                                    if(articles_chapitre)
                                        return (<div ref={ this.setReference }>
                                            <ChapitreView
                                                match={ this.props.match }
                                                display={ this.state.display }
                                                articles={ articles_chapitre }
                                                clientHeight={ clientHeight }
                                                clientWidth={ clientWidth }
                                                article={ article }
                                                allArticles={ articles }
                                                categories={ currentNumero.categories }
                                                numero={ currentNumero }
                                            />
                                        </div>);
                                    else return "Pas d'articles dans ce chapitre";

                                return "Article network status";

                            }}
                        </Query>
                    );

                }}
            </Query>
        )
    }
}

Chapitre.contextTypes = {
    magazine: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        render: state.renders.article
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        renderArticle: () => {
            dispatch({
                type: 'RENDER_ARTICLE'
            });
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(Chapitre)));


const ChapitreView = ({ clientHeight, clientWidth, article, match, display, articles, categories, allArticles, numero}) => {

    const classes = classNames({
        'player-module': true,
        'active': display,
    });

    return(
        <SettingsContext.Consumer>
            {({ fonts }) => (
                <div>
                    <article className="article">
                        <ArticleContent match={ match } fonts={ fonts } />
                    </article>

                    <div className={ classes }>
                        <Player match={ match } className={ classes }
                                articles={ articles }
                                categories={ categories }
                                allArticles={ allArticles }
                                numero={ numero }
                                fonts={ fonts }
                        />
                    </div>

                </div>
            )}
        </SettingsContext.Consumer>
    )

};

